import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CaptchaComponentBase } from 'src/shared/components/captcha-component-base';
import {
  DigitalklangService,
  ContactInformation,
} from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-contact-sub-form',
  templateUrl: './contact-sub-form.component.html',
  styleUrls: ['./contact-sub-form.component.less'],
})
export class ContactSubFormComponent
  // extends CaptchaComponentBase
  implements OnInit
{
  form!: UntypedFormGroup;
  isSubmitting = false;

  constructor(
    private _fb: UntypedFormBuilder,
    private _digitalklangService: DigitalklangService,
    private _messageService: NzMessageService,

  ) {}

  ngOnInit(): void {
    // this.initCaptcha();
    this.form = this._fb.group(
      {
        name: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
        telephone: [null, [Validators.required]],
        message: [null, [Validators.required]],
        agreement: [false, [Validators.requiredTrue]],
      },
      { updateOn: 'blur' }
    );
  }

  submitForm(): Promise<void> {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
  
    if (!this.form.valid) {
      return;
    }
  
    this.isSubmitting = true;

    const contactInformation: ContactInformation = Object.assign(
      this.form.value
    );
  
    this._digitalklangService
      .sendContact(contactInformation)
      .then(() => {
        this.form.reset();
        this._messageService.success(
          'Vielen Dank für Ihre Anfrage. Wir werden uns baldmöglichst mit Ihnen in Verbindung setzen.',
          {
            nzDuration: 5000,
          }
        );
      })
      .catch(() => {
        this._messageService.error(
          'Absenden fehlgeschlagen, bitte versuchen Sie es später erneut.'
        );
      })
      .finally(() => {
        this.isSubmitting = false;
        this.form.enable();
      });
  }
}
