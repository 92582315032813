<div class="header" [class.header--light]="lightMode && !isLoading">
    <div nz-row nzJustify="start" nzAlign="middle">
        <div nz-col nzFlex="180px" class="logo">
            <a routerLink="/" aria-label="Digitalklang-Logo: Navigiere zu unserer Startseite">
                <img *ngIf="isLoading || !isLoading && !lightMode" alt="digitalklang logo" class="logo__img" src="../../../assets/logos/digitalklang.svg">
            </a>
            <a routerLink="/" aria-label="Digitalklang-Logo: Navigiere zu unserer Startseite">
                <img *ngIf="!isLoading && lightMode" alt="digitalklang logo" class="logo__img" src="../../../assets/logos/digitalklang_inverted.svg">
            </a>
        </div>
        <div nz-col nzFlex="auto" class="navigation--full">
            <ul nz-menu nzMode="horizontal" class="navbar">
                <li nz-submenu class="navbar__submenu" (nzOpenChange)="toggleSubmenu($event)">
                    <span title class="navbar__submenu__title">
                        <span>Leistungen</span>
                        <span nz-icon [nzType]="submenuOpen ? 'up' : 'down'" nzTheme="outline"></span>
                    </span>
                    <ul>
                        <li nz-menu-item nzMatchRouter><a routerLink="portalentwicklung">Portalentwicklung</a></li>
                        <li nz-menu-item nzMatchRouter><a routerLink="anwendungsentwicklung">Anwendungsentwicklung</a></li>
                        <li nz-menu-item nzMatchRouter><a routerLink="microsoft-entwicklung">Microsoft Entwicklung</a></li>
                        <li nz-menu-item nzMatchRouter><a routerLink="digitalberatung">Digitalberatung</a></li>
                        <li nz-menu-item nzMatchRouter><a routerLink="cybersecurity">Cybersecurity</a></li>
                        <li nz-menu-item nzMatchRouter><a routerLink="kuenstliche-intelligenz">Künstliche Intelligenz</a></li>
                    </ul>
                </li>
                <li nz-menu-item nzMatchRouter><a routerLink="referenzen">Referenzen</a></li>
                <li nz-menu-item nzMatchRouter><a routerLink="ueber-uns">Über uns</a></li>
                <li nz-menu-item nzMatchRouter><a routerLink="karriere">Karriere</a></li>
                <li nz-menu-item class="navbar__contact"><button nz-button nzType="primary" nzSize="large" routerLink="kontakt" aria-label="Nehme Kontakt auf">Jetzt Kontakt aufnehmen!</button></li>
            </ul>
        </div>
        <div nz-col nzFlex="auto" nzXl="0" class="navigation--burger">
          <div class="burger-menu__img">
            <span (click)="switchStateOfDrawer()">
                <i nz-icon [nzType]="!drawerVisible ? 'menu' : 'close'" nzTheme="outline"></i>
            </span>
          </div>
          <nz-drawer
            [nzClosable]="false"
            [nzVisible]="drawerVisible"
            nzPlacement="right"
            >
            <ng-container *nzDrawerContent class="drawer-container">
                <ul nz-menu nzMode="inline" class="drawer-menu">
                    <li nz-submenu class="navbar__submenu__mobile" (nzOpenChange)="toggleSubmenu($event)">
                        <span title class="navbar__submenu__mobile__title">
                            <span>Leistungen</span>
                            <span nz-icon [nzType]="submenuOpen ? 'up' : 'down'" nzTheme="outline"></span>
                        </span>
                        <ul>
                            <li nz-menu-item nzMatchRouter><a routerLink="portalentwicklung" (click)="closeDrawer()">Portalentwicklung</a></li>
                            <li nz-menu-item nzMatchRouter><a routerLink="anwendungsentwicklung" (click)="closeDrawer()">Anwendungsentwicklung</a></li>
                            <li nz-menu-item nzMatchRouter><a routerLink="microsoft-entwicklung" (click)="closeDrawer()">Microsoft Entwicklung</a></li>
                            <li nz-menu-item nzMatchRouter><a routerLink="digitalberatung" (click)="closeDrawer()">Digitalberatung</a></li>
                            <li nz-menu-item nzMatchRouter><a routerLink="cybersecurity" (click)="closeDrawer()">Cybersecurity</a></li>
                            <li nz-menu-item nzMatchRouter><a routerLink="kuenstliche-intelligenz" (click)="closeDrawer()">Künstliche Intelligenz</a></li>
                        </ul>
                    </li>
                    <li nz-menu-item nzMatchRouter><a routerLink="referenzen" (click)="closeDrawer()">Referenzen</a></li>
                    <li nz-menu-item nzMatchRouter><a routerLink="ueber-uns" (click)="closeDrawer()">Über uns</a></li>
                    <li nz-menu-item nzMatchRouter><a routerLink="karriere" (click)="closeDrawer()">Karriere</a></li>
                    <li nz-menu-item nzMatchRouter class="navbar__contact" routerLink="kontakt" (click)="closeDrawer()">Jetzt Kontakt aufnehmen!</li>
                </ul>
            </ng-container>
            </nz-drawer>
        </div>
    </div>
</div>
